import * as React from "react";
import PropTypes from "prop-types";

import Box from "@mui/material/Box";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import MenuItem from "@mui/material/MenuItem";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import ToggleColorMode from "./ToggleColorMode";
import { useTheme } from "@mui/system";
import { ExitToApp } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { LanguageSelector } from "../../../components/LanguageSelector/LanguageSelector";
import { getLoginUrl } from "../../../UrlUtil";

const logoStyle = {
  width: "140px",
  height: "auto",
  cursor: "pointer",
};

function AppAppBar({ mode, toggleColorMode }) {
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);
  const theme = useTheme();
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: "smooth" });
      window.scrollTo({
        top: targetScroll,
        behavior: "smooth",
      });
      setOpen(false);
    }
  };

  return (
    <div>
      <AppBar
        position="fixed"
        sx={{
          boxShadow: 0,
          bgcolor: "transparent",
          backgroundImage: "none",
          mt: 2,
        }}
      >
        <Container maxWidth="lg">
          <Toolbar
            variant="regular"
            sx={(theme) => ({
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexShrink: 0,
              borderRadius: "999px",
              bgcolor:
                theme.palette.mode === "light"
                  ? "rgba(255, 255, 255, 0.4)"
                  : "rgba(0, 0, 0, 0.4)",
              backdropFilter: "blur(24px)",
              maxHeight: 40,
              border: "1px solid",
              borderColor: "divider",
              boxShadow:
                theme.palette.mode === "light"
                  ? `0 0 1px rgba(85, 166, 246, 0.1), 1px 1.5px 2px -1px rgba(85, 166, 246, 0.15), 4px 4px 12px -2.5px rgba(85, 166, 246, 0.15)`
                  : "0 0 1px rgba(2, 31, 59, 0.7), 1px 1.5px 2px -1px rgba(2, 31, 59, 0.65), 4px 4px 12px -2.5px rgba(2, 31, 59, 0.65)",
            })}
          >
            <Box
              sx={{
                flexGrow: 1,
                display: "flex",
                alignItems: "center",
                px: 0,
              }}
            >
              <img
                src={
                  theme.palette.mode === "light"
                    ? "https://storage.googleapis.com/pifa.se/partihandel.se_row.png"
                    : "https://storage.googleapis.com/pifa.se/partihandel.se_row_inverted.png"
                }
                style={logoStyle}
                alt="logo of sitemark"
              />
              <Box sx={{ display: { xs: "none", md: "flex" } }}>
                <MenuItem
                  onClick={() => scrollToSection("features")}
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography variant="body2" color="text.primary">
                    {t("landingpage.topbar.features")}
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => scrollToSection("highlights")}
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography variant="body2" color="text.primary">
                    {t("landingpage.topbar.highlights")}
                  </Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => scrollToSection("faq")}
                  sx={{ py: "6px", px: "12px" }}
                >
                  <Typography variant="body2" color="text.primary">
                    {t("landingpage.topbar.faq")}
                  </Typography>
                </MenuItem>
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                gap: 2,
                alignItems: "center",
                opacity: 1,
              }}
            >
              <LanguageSelector
                color={theme.palette.mode === "light" ? "black" : "white"}
              />
              <ToggleColorMode mode={mode} toggleColorMode={toggleColorMode} />

              <Button
                variant="contained"
                size="large"
                component="a"
                href={getLoginUrl()}
                sx={{
                  background:
                    theme.palette.mode === "light" ? "black" : "white",
                  color: theme.palette.mode === "light" ? "white" : "black",
                  "&:hover": {
                    backgroundColor:
                      theme.palette.mode === "light" ? "white" : "black",
                    color: theme.palette.mode === "light" ? "black" : "white",
                  },
                }}
                endIcon={<ExitToApp />}
              >
                {t("topbar.login")}
              </Button>
            </Box>
            <Box
              sx={{
                display: { sm: "flex", md: "none" },
                justifyContent: "right",
                gap: "1rem",
                flexDirection: "row",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "right",
                  alignItems: "center",
                  alignContent: "center",
                  gap: "1rem",
                  flexGrow: 1,
                }}
              >
                <LanguageSelector
                  color={theme.palette.mode === "light" ? "black" : "white"}
                />
                <ToggleColorMode
                  mode={mode}
                  toggleColorMode={toggleColorMode}
                />

                <Button
                  variant="text"
                  color="primary"
                  aria-label="menu"
                  onClick={toggleDrawer(true)}
                  sx={{ minWidth: "30px", p: "4px" }}
                >
                  <MenuIcon
                    sx={{
                      color: theme.palette.mode === "light" ? "black" : "white",
                    }}
                  />
                </Button>
              </Box>

              <Drawer anchor="right" open={open} onClose={toggleDrawer(false)}>
                <Box
                  sx={{
                    minWidth: "60dvw",
                    p: 2,
                    backgroundColor: "background.paper",
                    flexGrow: 1,
                  }}
                >
                  <MenuItem>
                    <Button
                      variant="contained"
                      size="large"
                      component="a"
                      href={getLoginUrl()}
                      sx={{
                        background:
                          theme.palette.mode === "light" ? "black" : "white",
                        color:
                          theme.palette.mode === "light" ? "white" : "black",
                        "&:hover": {
                          backgroundColor:
                            theme.palette.mode === "light" ? "white" : "black",
                          color:
                            theme.palette.mode === "light" ? "black" : "white",
                        },
                      }}
                      endIcon={<ExitToApp />}
                    >
                      {t("topbar.login")}
                    </Button>
                  </MenuItem>
                  <Divider />
                  <MenuItem onClick={() => scrollToSection("features")}>
                    {t("landingpage.topbar.features")}
                  </MenuItem>
                  <MenuItem onClick={() => scrollToSection("highlights")}>
                    {t("landingpage.topbar.highlights")}
                  </MenuItem>
                  <MenuItem onClick={() => scrollToSection("faq")}>
                    {t("landingpage.topbar.faq")}
                  </MenuItem>
                  <Divider />
                </Box>
              </Drawer>
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

AppAppBar.propTypes = {
  mode: PropTypes.oneOf(["dark", "light"]).isRequired,
  toggleColorMode: PropTypes.func.isRequired,
};

export default AppAppBar;
