const moment = require("moment-timezone");

export const get10DaysBack = () => {
  return getDaysBack(10);
};

export const get180DaysBack = () => {
  return getDaysBack(180);
};

export const get30DaysBack = () => {
  return getDaysBack(30);
};

export const get365DaysBack = () => {
  return getDaysBack(365);
};

export const get60DaysBack = () => {
  return getDaysBack(60);
};

export const get90DaysBack = () => {
  return getDaysBack(90);
};

export function getCurrentMonth() {
  var today = new Date();
  var firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
  var lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  return {
    firstDay: firstDayOfMonth,
    lastDay: lastDayOfMonth,
  };
}

export function getCurrentQuarter() {
  var today = new Date();
  var quarter = Math.floor((today.getMonth() + 3) / 3);
  var firstDayOfQuarter = new Date(today.getFullYear(), (quarter - 1) * 3, 1);
  var lastDayOfQuarter = new Date(today.getFullYear(), quarter * 3, 0);

  return {
    firstDay: firstDayOfQuarter,
    lastDay: lastDayOfQuarter,
  };
}

export function getCurrentWeek() {
  var today = new Date();
  var firstDayOfWeek = new Date(
    today.setDate(today.getDate() - today.getDay())
  );
  var lastDayOfWeek = new Date(firstDayOfWeek);
  lastDayOfWeek.setDate(lastDayOfWeek.getDate() + 6);

  return {
    firstDay: firstDayOfWeek,
    lastDay: lastDayOfWeek,
  };
}

export function getCurrentYear() {
  var today = new Date();
  var firstDayOfYear = new Date(today.getFullYear(), 0, 1);
  var lastDayOfYear = new Date(today.getFullYear(), 11, 31);

  return {
    firstDay: firstDayOfYear,
    lastDay: lastDayOfYear,
  };
}

export const getDateAndWeekday = (item, mode) => {
  if (item !== null && item !== undefined) {
    if (typeof item === "string") {
      if (mode === "date") {
        const date = new Date(item);
        return (
          date.toLocaleDateString("sv-SE") +
          " " +
          date.toLocaleString("sv-SE", { weekday: "long" })
        );
      } else if (mode === "dateandtime") {
        return item.slice(0, 10) + " " + item.slice(11, 16);
      }
    }
  } else {
    return "Ogiltigt datum";
  }
};

export const getDateDescriptionAndTime = (inputDate) => {
  // Get current date
  const currentDate = new Date();

  // Get the hours and minutes of the input date
  const inputDateHours = inputDate.getHours();
  const inputDateMinutes = inputDate.getMinutes();

  // Format the time properly
  const inputDateTime =
    inputDateHours +
    ":" +
    (inputDateMinutes < 10 ? "0" : "") +
    inputDateMinutes;

  // Set the time of the current date to midnight
  currentDate.setHours(0, 0, 0, 0);

  // Set the time of the input date to midnight
  inputDate.setHours(0, 0, 0, 0);

  // Calculate the difference in milliseconds between the two dates
  const timeDifference = inputDate - currentDate;

  // Calculate the number of milliseconds in a day
  const oneDay = 24 * 60 * 60 * 1000;

  // Get the current year and the year of the input date
  const currentYear = currentDate.getFullYear();
  const inputYear = inputDate.getFullYear();

  // Check if the input date is in the past
  if (timeDifference < 0) {
    const year = inputDate.getFullYear();
    const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
    const day = inputDate.getDate().toString().padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  // Check if the input date is today, tomorrow, or within the next 6 days
  if (timeDifference >= 0 && timeDifference < oneDay) {
    return "Idag kl " + inputDateTime;
  } else if (timeDifference >= oneDay && timeDifference < oneDay * 2) {
    return "Imorgon kl " + inputDateTime;
  } else if (timeDifference >= oneDay * 2 && timeDifference < oneDay * 7) {
    // Return the name of the weekday for the next 6 days
    const weekdays = [
      "Söndag",
      "Måndag",
      "Tisdag",
      "Onsdag",
      "Torsdag",
      "Fredag",
      "Lördag",
    ];
    const dayIndex =
      (currentDate.getDay() + Math.floor(timeDifference / oneDay)) % 7;
    return weekdays[dayIndex] + " kl " + inputDateTime;
  } else {
    // Check if the input date is in the current year
    const options =
      inputYear === currentYear
        ? { year: "numeric", month: "short", day: "numeric" }
        : {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
          };

    return (
      inputDate.toLocaleDateString("sv-SE", options) + " kl " + inputDateTime
    );
  }
};

export const getDateDescriptionText = (inputDate) => {
  // Get current date
  const currentDate = new Date();

  // Set the time of the current date to midnight
  currentDate.setHours(0, 0, 0, 0);

  // Set the time of the input date to midnight
  inputDate.setHours(0, 0, 0, 0);

  // Calculate the difference in milliseconds between the two dates
  const timeDifference = inputDate - currentDate;

  // Calculate the number of milliseconds in a day
  const oneDay = 24 * 60 * 60 * 1000;

  // Check if the input date is today, tomorrow, or within the next 6 days
  if (timeDifference >= 0 && timeDifference < oneDay) {
    return "Idag";
  } else if (timeDifference >= oneDay && timeDifference < oneDay * 2) {
    return "Imorgon";
  } else if (timeDifference >= oneDay * 2 && timeDifference < oneDay * 7) {
    // Return the name of the weekday for the next 6 days
    const weekdays = [
      "Söndag",
      "Måndag",
      "Tisdag",
      "Onsdag",
      "Torsdag",
      "Fredag",
      "Lördag",
    ];
    const dayIndex =
      (currentDate.getDay() + Math.floor(timeDifference / oneDay)) % 7;
    return weekdays[dayIndex];
  } else {
    return getLocalDateTimeShort(inputDate, "date");
  }
};
export const getDateDescriptionTextSwedishTimezone = (inputDate) => {
  // Check if inputDate is valid
  if (!inputDate || !(inputDate instanceof Date) || isNaN(inputDate)) {
    return ""; // Return an empty string for null, undefined, or invalid dates
  }

  // Get current date and time
  const currentDate = new Date();

  // Save the input time
  const inputTime = inputDate.toLocaleTimeString("sv-SE", {
    hour: "2-digit",
    minute: "2-digit",
  });

  // Set the time of the current date to midnight
  currentDate.setHours(0, 0, 0, 0);

  // Set the time of the input date to midnight
  inputDate.setHours(0, 0, 0, 0);

  // Calculate the difference in milliseconds between the two dates
  const timeDifference = inputDate - currentDate;

  // Calculate the number of milliseconds in a day
  const oneDay = 24 * 60 * 60 * 1000;

  // Check if the input date is today, yesterday, or within the next 6 days
  if (timeDifference >= 0 && timeDifference < oneDay) {
    return `Idag Kl. ${inputTime}`;
  } else if (timeDifference >= -oneDay && timeDifference < 0) {
    return `Igår Kl. ${inputTime}`;
  } else if (timeDifference >= oneDay && timeDifference < oneDay * 2) {
    return "Imorgon";
  } else if (timeDifference >= oneDay * 2 && timeDifference < oneDay * 7) {
    // Return the name of the weekday for the next 6 days
    const weekdays = [
      "Söndag",
      "Måndag",
      "Tisdag",
      "Onsdag",
      "Torsdag",
      "Fredag",
      "Lördag",
    ];
    const dayIndex =
      (currentDate.getDay() + Math.floor(timeDifference / oneDay)) % 7;
    return weekdays[dayIndex];
  } else {
    // Format date as "DayName YYYY-MM-DD Kl. time"
    const weekdays = [
      "Söndag",
      "Måndag",
      "Tisdag",
      "Onsdag",
      "Torsdag",
      "Fredag",
      "Lördag",
    ];
    const dayName = weekdays[inputDate.getDay()];
    const dateString = inputDate.toISOString().split("T")[0];
    return `${dayName} ${dateString} Kl. ${inputTime}`;
  }
};
export function getDaysBack(days) {
  var today = new Date();
  var pastDate = new Date(today.setDate(today.getDate() - days));

  return {
    pastDate: pastDate,
  };
}

export function getFirstAndLastDateOfPreviousMonth() {
  var today = new Date(); // Current date
  var firstDayOfPreviousMonth = new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    1
  ); // First day of previous month
  var lastDayOfPreviousMonth = new Date(
    today.getFullYear(),
    today.getMonth(),
    0
  ); // Last day of previous month

  return {
    firstDay: firstDayOfPreviousMonth,
    lastDay: lastDayOfPreviousMonth,
  };
}

export const getLocalDateTime = (item, mode) => {
  if (item !== null && item !== undefined) {
    if (typeof item === "string") {
      if (mode === "date") {
        return item.slice(0, 10);
      } else if (mode === "dateandtime") {
        return item.slice(0, 10) + " " + item.slice(11, 16);
      } else if (mode === "dateandtimeandseconds") {
        return item.slice(0, 10) + " " + item.slice(11, 19);
      }
    } else if (typeof item === "object") {
      if (mode === "date") {
        return item.toISOString().slice(0, 10);
      } else if (mode === "dateandtime") {
        return (
          item.toISOString().slice(0, 10) +
          " " +
          item.toISOString().slice(11, 16)
        );
      } else if (mode === "dateandtimeandseconds") {
        return (
          item.toISOString().slice(0, 10) +
          " " +
          item.toISOString().slice(11, 19)
        );
      }
    }
  } else {
    return "";
  }
};

export const getLocalDateTimeShort = (item, mode) => {
  if (item !== null && item !== undefined) {
    let localDate;

    if (typeof item === "string") {
      localDate = new Date(item);
    } else if (typeof item === "object") {
      localDate = new Date(item.toISOString());
    }

    // Adjust to GMT+1
    localDate.setHours(localDate.getHours() + 1);

    if (mode === "date") {
      return localDate.toISOString().slice(5, 10);
    } else if (mode === "dateandtime") {
      return (
        localDate.toISOString().slice(5, 10) +
        " " +
        localDate.toISOString().slice(11, 16)
      );
    }
  } else {
    return "";
  }
};

export const getLocalDateTimeShortSwedishTimeZone = (item, mode) => {
  if (item !== null && item !== undefined) {
    let localDate;

    if (typeof item === "string") {
      localDate = new Date(item);
    } else if (typeof item === "object") {
      localDate = new Date(item.toISOString());
    }

    // Convert to Swedish time zone (CET/CEST)
    localDate = convertToSwedishTime(localDate);

    if (mode === "date") {
      return localDate.toISOString().slice(5, 10);
    } else if (mode === "dateandtime") {
      return (
        localDate.toISOString().slice(5, 10) +
        " " +
        localDate.toISOString().slice(11, 16)
      );
    }
  } else {
    return "";
  }
};

export const getLocalDateTimeSwedishTimeZone = (item, mode) => {
  if (item !== null && item !== undefined) {
    let localDate;
    if (typeof item === "string") {
      localDate = new Date(item);
    } else if (typeof item === "object") {
      localDate = item;
    }

    // Convert to Swedish time zone (CET/CEST)
    localDate = convertToSwedishTime(localDate);

    if (mode === "date") {
      return localDate.toISOString().slice(0, 10);
    } else if (mode === "dateandtime") {
      return (
        localDate.toISOString().slice(0, 10) +
        " " +
        localDate.toISOString().slice(11, 16)
      );
    } else if (mode === "dateandtimeandseconds") {
      return (
        localDate.toISOString().slice(0, 10) +
        " " +
        localDate.toISOString().slice(11, 19)
      );
    }
  } else {
    return "";
  }
};

export function getLastMonth() {
  var today = new Date();
  var firstDayOfLastMonth = new Date(
    today.getFullYear(),
    today.getMonth() - 1,
    1
  );
  var lastDayOfLastMonth = new Date(today.getFullYear(), today.getMonth(), 0);

  return {
    firstDay: firstDayOfLastMonth,
    lastDay: lastDayOfLastMonth,
  };
}

export function getLastQuarter() {
  var today = new Date();
  var quarter = Math.floor((today.getMonth() + 3) / 3) - 1;
  var firstDayOfLastQuarter = new Date(
    today.getFullYear(),
    (quarter - 1) * 3,
    1
  );
  var lastDayOfLastQuarter = new Date(today.getFullYear(), quarter * 3, 0);

  return {
    firstDay: firstDayOfLastQuarter,
    lastDay: lastDayOfLastQuarter,
  };
}

export function getLastWeek() {
  var today = new Date();
  var firstDayOfLastWeek = new Date(
    today.setDate(today.getDate() - today.getDay() - 7)
  );
  var lastDayOfLastWeek = new Date(firstDayOfLastWeek);
  lastDayOfLastWeek.setDate(lastDayOfLastWeek.getDate() + 6);

  return {
    firstDay: firstDayOfLastWeek,
    lastDay: lastDayOfLastWeek,
  };
}

export function getLastYear() {
  var today = new Date();
  var firstDayOfLastYear = new Date(today.getFullYear() - 1, 0, 1);
  var lastDayOfLastYear = new Date(today.getFullYear() - 1, 11, 31);

  return {
    firstDay: firstDayOfLastYear,
    lastDay: lastDayOfLastYear,
  };
}

export function getPastYearsBack(years) {
  const today = new Date();
  const pastDate = new Date();
  pastDate.setFullYear(today.getFullYear() - years);
  return { pastDate: pastDate };
}

export const isDateGreaterThanToday = (targetDate) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  targetDate.setHours(0, 0, 0, 0);

  return targetDate.getTime() > today.getTime();
};
export const isArrivalDateToday = (targetDate) => {
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  targetDate.setHours(0, 0, 0, 0);

  return targetDate.getTime() === today.getTime();
};

export const isFortnoxTokenExpired = (expirationDate) => {
  const currentTime = moment.tz("Europe/Stockholm");
  const tokenExpirationTime = moment.tz(expirationDate, "Europe/Stockholm");
  return currentTime.isAfter(tokenExpirationTime);
};
export const stocktakeDateLongerThan6hoursAgo = (targetTime) => {
  const targetDate = new Date(targetTime);

  const currentDate = new Date();

  const timeDifference = currentDate - targetDate;

  const sixHoursInMillis = 6 * 60 * 60 * 1000;

  return timeDifference > sixHoursInMillis;
};

export const timeShorterThan10SecondsAgo = (inputDate) => {
  // Get the current time
  var currentTime = new Date();
  var compareDate = new Date(inputDate);
  // Calculate the time difference in milliseconds
  var timeDifference = currentTime.getTime() - compareDate.getTime();

  // Convert milliseconds to seconds
  var timeDifferenceInSeconds = timeDifference / 1000;

  // Check if the time difference is less than 10 seconds
  return timeDifferenceInSeconds < 10;
};

const convertToSwedishTime = (date) => {
  const options = { timeZone: "Europe/Stockholm" };
  const swedishTimeString = date.toLocaleString("sv-SE", options);
  return new Date(swedishTimeString);
};
