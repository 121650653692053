const productionDomains = [
  "engros.ae",
  "engros.app",
  "engros.at",
  "engros.dev",
  "engros.es",
  "engros.fi",
  "engros.in",
  "engros.io",
  "engros.it",
  "engros.nz",
  "engros.pt",
  "engros.se",
  "engros.uk",
  "partihandel.io",
  "partihandel.se",
  "partihanteraren.com",
  "pifa.se",
];

const getEnvironment = () => {
  const host = window.location.host;
  if (productionDomains.some((domain) => host.includes(domain))) {
    return "production";
  } else if (host.includes("pifa.dev")) {
    return "sandbox";
  } else if (host.includes("localhost:8080")) {
    return "localproduction";
  } else {
    return "development";
  }
};

const environment = getEnvironment();

const getLoginUrl = () => {
  switch (environment) {
    case "production":
      return `https://id.pifa.se`;
    case "sandbox":
      return "https://id.pifa.dev";
    case "localproduction":
      return "http://id.localhost:8080";
    default:
      return "http://localhost:2000";
  }
};
const getApiBaseUrl = () => {
  switch (environment) {
    case "production":
      return `https://api.pifa.se`;
    case "sandbox":
      return "https://api.pifa.dev";
    default:
      return "http://localhost:8000";
  }
};
export { getLoginUrl, getApiBaseUrl };
