import React from "react";
import styles from "./BettinaLoader.module.css";

const BettinaLoader = ({ loadingText }) => {
  return (
    <div className={styles.loaderContainer}>
      <svg
        className={styles.spinner}
        stroke="#000"
        width="200"
        height="200"
        viewBox="0 0 206 206"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle
          className={styles.path}
          fill="none"
          strokeWidth="4"
          strokeLinecap="round"
          cx="103"
          cy="103"
          r="100"
        ></circle>
      </svg>

      <h4>{loadingText}</h4>
    </div>
  );
};
export default BettinaLoader;
